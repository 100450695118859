/***********************************************************************
 utils contains useful utility functions that are commonly used by 
 this MPA
************************************************************************/
import $ from 'jquery';
import axios from 'axios';
import { pdf, previous, returnButton } from './components';
import { domain, month, route, step } from './config';

/**
 * Utility API function for the mapping of api routes for tracked items in v1/Participant Model {@link https://github.com/chizuo/evp-portal-api/blob/main/docs/v1/participant.md}
 * @param {string} item - the item for the API call
 * @param {object} account - the account for the API call
 * @param {number} retries - the amount of times an API attempt had to be made
 */
async function api(item, account, retries=0) {
    try {
        await axios.put(`${domain}/${route.get(item)}`, {uid: account.uid}); 
        let isGuide = item.includes('Guide');
        if(step.get(item) === 'step1') {
            item = isGuide ? 'Guide' : item;
            account.tracked.step1[item]++;
        }
        else if(step.get(item) === 'step2') {
            item = isGuide ? 'Guide' : item;
            account.tracked.step2[item]++;
        }
        else if(step.get(item) === 'step3') {
            item = isGuide ? 'Guide' : item;
            account.tracked.step3[item]++;
        }
        else if(step.get(item) === 'step4') {
            item = isGuide ? 'Guide' : item;
            account.tracked.step4[item]++;
        }
        else if(step.get(item) === 'budgetManagement') {
            item = isGuide ? 'Guide' : item;
            account.tracked.budgetManagement[item]++;
        }
        else console.error(`Section for ${item} does not exist in the configuration`);
        sessionStorage.setItem('evp_participant', JSON.stringify(account)); 
    } catch(e) {
        ++retries;
        console.error(e.message);
        sleep(retries*250).then(()=> { if(retries < 5) api(item, account, retries);}); 
    }
}

/**
 * Creates the PDF interface for the portal
 * @param {string} path - path of the PDF
 * @param {function} prev - function that loads the previous interface
 */
function resource(path, prev) {
    $('#interface').html(`
        ${returnButton()}
        ${pdf(path)}
    `);
    previous(prev);
}

/**
 * Utility function to dynamically set the iframe based on the window.
 * @param {integer} h - integer representing a percentage to be set for height based on window size, defaults to 80.
 * @param {integer} w - integer representing a percentage to be set for width based on window size, defaults to 80.
 * @returns {Object} returns the object { iframeHeight, iframeWidth }
 */
function iFramer(h = 80, w = 80) {
    const height = h / 100;
    const width = w / 100;
    // Get the new screen width and height
    const screenWidth = $(window).width();
    const screenHeight = $(window).height();
    
    // Calculate the new dimensions for the iframe
    const iframeWidth = screenWidth * width;  
    const iframeHeight = screenHeight * height;
    
    // Set the new dimensions
    $("iframe").css({
        "width": iframeWidth,
        "height": iframeHeight
    });

    return { iframeHeight, iframeWidth }
}

/**
 * Returns the full name of the month.
 * @param {integer} m - number representing the month, starting at 0 as January to 11 as December.
 * @param {integer} d - number representing the month, starting at 0 as January to 11 as December.
 * @param {integer} y - number representing the month, starting at 0 as January to 11 as December.
 * @returns {string} formatted date as a string based in parameters provided, e.g. January 1, 2000, returns an empty string if no parameters are provided
 */
function date(m = -1 , y = -1, d = 0) {
    const d30 = new Set([3,5,8,10]);
    
    let md = m >= 0 && m <= 11 ? month.get(m) : ''; // validate m & gets month of m
    
    if(d && md.length) { // validates d if d was provided & m was correctly provided
        if(m === 1 && d > 28) { // prevents incorrect February dates, including leap years
            d = 28; 
        } else if(d30.has(m) && d > 30) { // correct max day on months with 30 days
            d = 30;
        } else { // correct max day of months with 31 days
            if(d > 31) d = 31; 
        }
        md += ' ' + d; // adds the day
    }

    let format = md.length && d ? ', ' : ' '; // adds comma if m,d are provided.
    if(y) md += format + y;
    return md;
}

/**
 * Function that pauses the thread for the indicated length, in milliseconds, that is passed in
 * @param {integer} ms - length of time, in milliseconds, that this function should cause a thread to sleep.
 * @returns {Promise} resolves after the parameter ms time
 */
function sleep(ms = 250) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Function that captures the module where the help icon was clicked to launch the help form application and
 * adds the step to the account object to store in sessionStorage for the help form application to retrieve.
 * @param {integer} ms - length of time, in milliseconds, that this function should cause a thread to sleep.
 */
function help(step, account) {
    account.step = step;
    sessionStorage.setItem('evp_participant', JSON.stringify(account));
    sleep(1000).then(() => window.location.href = 'help_request');
}

/**
 * Function that will make the interface run a transition animation to a new DOM element or new document
 * @param {function} next - callback function that either changes the document or changes the #interface element.
 */
function transition(next) {
    let animationCount = $('.right').length + $('.left').length;
    
    function animationEnded() {
        animationCount--;
        if (animationCount === 0) {
            next();
        }
    }

    $('.right').each(function() { 
        $(this).removeClass('animate__animated animate__backInRight animate__backInLeft');
        $(this).addClass('animate__animated animate__backOutRight');
        $(this).one('animationend', animationEnded);
    });

    $('.left').each(function() {
        $(this).removeClass('animate__animated animate__backInLeft');
        $(this).addClass('animate__animated animate__backOutLeft');
        $(this).one('animationend', animationEnded);
    });

    $('.zoom').each(function() {
        $(this).removeClass('animate__animated animate__zoomIn');
        $(this).addClass('animate__animated animate__zoomOut');
        $(this).one('animationend', animationEnded);
    });
}

function cachePost(account, form) {
    account.post = form;
    sessionStorage.setItem('evp_participant', JSON.stringify(account));
}

function cacheClear(account) {
    delete account.post;
    sessionStorage.setItem('evp_participant', JSON.stringify(account));
}

export {
    api,
    cachePost,
    cacheClear,
    date,
    help,
    iFramer,
    resource,
    sleep,
    transition
};